:root {
  --toastify-font-family: "FiraGO", sans-serif;
  --toastify-color-success: #00c6be;
  --toastify-color-error: #ff3b30;
  --toastify-toast-width: 420px;
  --toastify-toast-min-height: 80px;
}

.Toastify {
  &__toast {
    font-size: 19px;
    line-height: 23px;
    border-radius: 14px;
    &-icon {
      width: 28px;
    }
  }
  &__progress-bar {
    height: 6px;
  }
}
