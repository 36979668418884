$main: #00c6be;
$black: #000;
$white: #fff;
$grey: rgba(0, 0, 0, 0.46);
$whiteGrey: rgba(255, 255, 255, 0.46);
$red: #ff3b30;
$transparentRed: rgba(255, 59, 48, 0.5);
$yellow: #f9b60a;
$purple: #e022ff;

$backgroundGrey: #f1f4f5;
$greyMobile: #1c1c1e;

$colors: (
  main: $main,
  black: $black,
  white: $white,
  grey: $grey,
  whiteGrey: $whiteGrey,
  red: $red,
  transparentRed: $transparentRed,
  yellow: $yellow,
  purple: $purple,
  greyMobile: $greyMobile,
);

@each $key, $color in $colors {
  .color-#{$key} {
    color: $color;
  }

  .bg-#{$key} {
    background-color: $color;
  }
}
