.root {
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  color: inherit;
  &:not(:disabled) {
    cursor: pointer;
  }
}
