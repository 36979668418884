.block {
  display: block;
}

.flex {
  display: flex;
}

.flex0\.5 {
  flex: 0.5;
}

.flex1 {
  flex: 1;
}

.flex1\.5 {
  flex: 1.5;
}

.flex1\.75 {
  flex: 1.75;
}

.wrap {
  flex-wrap: wrap;
}

.gap16 {
  gap: 16px;
}

.gap32 {
  gap: 32px;
}

.shrink0 {
  flex-shrink: 0;
}

.self-start {
  align-self: flex-start;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-with-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.row-with-wrap-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.row-align-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-align-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column-space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.column-to-row {
  display: flex;
  flex-direction: column;
  @media (max-width: 1782px) {
    flex-direction: row;
  }
}

.justify-content-end {
  justify-content: end;
}

.zIndex2 {
  z-index: 2;
}

.br10 {
  border-radius: 10px;
}

.br14 {
  border-radius: 14px;
}
