.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w1\/2 {
  width: calc((100% - 16px) / 2);
}

.w1\/5 {
  width: calc((100% - 64px) / 5);
}

.w2\/5 {
  width: calc((100% - 24px) * 2 / 5);
}

.w3\/5 {
  width: calc((100% - 12px) * 3 / 5);
}

.w4\/5 {
  width: calc((100% - 6px) * 4 / 5);
}

.w460 {
  width: 460px;
}

.w515 {
  width: 515px;
}

.w630 {
  width: 630px;
}

.w980 {
  width: 980px;
}

.mw440 {
  max-width: 440px;
}

.mw912 {
  max-width: 912px;
}

.mw460 {
  max-width: 460px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h85 {
  height: 85px;
}

.h252 {
  height: 252px;
}

.mh100vh {
  min-height: 100vh;
}
