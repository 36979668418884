.media {
  width: 100%;
  height: 30rem;
  object-fit: contain;
}

.carousel {
  position: relative;
  width: 100%;
  height: 300px;
}

.item {
  background-color: black;
}

.index {
  position: absolute;
  top: 0.1rem;
  font-size: 0.7rem;
  font-weight: bold;
  font-family: Roboto;
  color: black;
  padding: 0.1rem 0.3rem;
  background-color: lightgray;
  left: 1rem;
  opacity: 0.75;
}

.caption-container {
  position: absolute;
  bottom: 0;
  left: 20rem;
  right: 20rem;
}

.caption {
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: Roboto;
  background-color: lightgray;
  color: black;
  width: fit-content;
  margin: 0 auto;
  opacity: 0.75;
}

.btn-prev,
.btn-next {
  position: absolute;
  font-size: 50px;
  color: lightgray;
  cursor: pointer;
  top: 13rem;
}
.btn-prev {
  left: 1rem;
}
.btn-next {
  right: 1rem;
}
