@import "css/colors";
@import "css/texts";

.discount {
  &-info {
    padding: 32px;
    background-color: $white;
    border-radius: 14px;
    min-width: 450px;
  }
  &-text {
    @extend .bold44-text;
    font-style: italic;
    font-size: 50px;
  }

  &-active {
    @extend .medium24-text;
    padding: 32px;
    background-color: $white;
    border-radius: 14px;
    margin-bottom: 16px;
    position: relative;
  }

  &-tag {
    position: absolute;
    top: -8px;
    right: 16px;
  }

  &-category {
    padding: 32px;
    border: 1px solid rgb(0, 0, 0, 0.14);
    border-radius: 14px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;
    }
  }
}
