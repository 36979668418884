@import "css/colors";
@import "css/texts";

.wrapper {
  border-radius: 14px;
  overflow: hidden;
  background-color: $black;
  width: 386px;
  flex: 1;
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.header {
  z-index: 3;
  padding-top: 43px;
  left: 0;
  right: 0;
  top: 0;
  height: 87px;
  display: flex;
  align-items: flex-end;
  padding: 0 16px 16px;
  position: absolute;
  backdrop-filter: blur(30px);
  background: hsla(0, 0%, 100%, 0.18);
}

.content {
  width: 100%;
  padding: 113px 0 152px;
  height: 100%;
  scrollbar-color: rgba(255, 255, 255, 0.46) transparent;
  scrollbar-width: thin;
  overflow-y: overlay;
}

.banner-wrapper {
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  width: 343px;
  height: 120px;

  &-default-img {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}

.separator {
  width: 100%;
  height: 0.5px;
  background-color: $whiteGrey;
}

.banner-button {
  position: relative;
  border-radius: 14px;
  height: 51px;
  width: 343px;
  background-color: $white;
  overflow: hidden;

  &-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 171.5px;
    bottom: 0;
    z-index: 1;
  }
}

.button-with-icon {
  background: $greyMobile;
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 4px;
  width: 109px;
  height: 60px;
}

.map-wrapper {
  width: 343px;
  height: 100px;
  border-radius: 14px;
  margin: 0 16px 16px;

  & > .map {
    width: 343px;
    height: 100px;
  }
}

.discount {
  position: absolute;
  right: 6px;
  bottom: 16px;
}

.short-logo {
  border-radius: 14px;
}

.arrow-icon {
  top: 2px;
  position: relative;
}
