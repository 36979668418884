@import "colors";
@import "texts";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "FiraGO", sans-serif;
  font-weight: 400;
  color: $black;
  background-color: $backgroundGrey;
}

button,
input {
  font-family: "FiraGO", sans-serif;
  font-weight: 400;
  color: $black;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.page-content {
  min-width: 1440px;
  padding: 0 32px 135px;
}

/* Texts, inputs */

::placeholder {
  color: $grey;
}

.font-system-ui {
  font-family: system-ui, sans-serif;
}

.text-center {
  text-align: center;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.unstyled-input {
  @extend .regular19-text;
  appearance: none;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  background-color: transparent;
}

.input-text {
  color: $black;
  font-size: 24px;
  line-height: 28px;
}

.search-input-wrapper {
  width: 485px;
  height: 61px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 21.73px;
    height: 21.94px;
    top: 20px;
    left: 34.7px;
  }
}

.search-input {
  @extend .unstyled-input;
  @extend .regular24-text;
  width: 485px;
  height: 61px;
  border-radius: 14px;
  background: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.46);
  padding: 19px 32px 19px 75px;
}

/* Other */

.pointer {
  cursor: pointer;
}

.translucent {
  opacity: 0.46;
}

.flip {
  transform: rotate(180deg);
}

.overflow-auto {
  overflow: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.divider {
  border-bottom: 0.5px solid $grey;
}

.unstyled-button {
  @extend .regular19-text;
  appearance: none;
  border: 0;
  padding: 0;
  background: transparent;
  text-align: left;
  display: block;
  width: 100%;
  &:not(:disabled) {
    cursor: pointer;
  }
}

.main-button {
  @extend .unstyled-button;
  @extend .medium19-text;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  color: $white;
  background-color: $main;
  border: 1px solid $main;
  border-radius: 14px;

  &:disabled {
    opacity: 0.46;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

.black-button {
  @extend .main-button;
  background-color: $black;
  border: 1px solid $black;
}

.white-button {
  @extend .unstyled-button;
  padding: 8px 32px;
  background-color: $white;
  border-radius: 14px;
}

.red-text-button {
  @extend .unstyled-button;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 460px;
  width: 100%;
  height: 53px;
  background-color: $white;
  border-radius: 14px;

  &:not(:disabled) {
    @extend .color-red;
    cursor: pointer;
  }
}

.card {
  background-color: $white;
  border-radius: 14px;
  border: 1px solid transparent;
}

.white-section {
  width: 460px;
  margin-bottom: 16px;
  border-radius: 14px;
  background-color: $white;
  border: 1px solid transparent;

  &.error {
    border-color: $red;
  }

  &-item {
    padding: 16px 32px;
  }
}

.white-section-description {
  @extend .regular19-text;
  @extend .color-grey;
  padding: 0 32px;
  width: 460px;
}
