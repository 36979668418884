.large-banner-wrapper {
  width: 375px;
  height: 256px;
  border-radius: 14px;
  overflow: hidden;
}

.lagre-banner-text-wrapper {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.medium-banner-wrapper {
  width: 333px;
  height: 106px;
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.medium-banner-text-wrapper {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.medium18-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.regular13-text {
  font-size: 13px;
  line-height: 16px;
}

.regular12-text {
  font-size: 12px;
  line-height: 14px;
}

.medium16-text {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex0\.5 {
  flex: 0.5;
}

.flex1 {
  flex: 1;
}

.flex1\.5 {
  flex: 1.5;
}

.flex1\.75 {
  flex: 1.75;
}

.wrap {
  flex-wrap: wrap;
}

.shrink0 {
  flex-shrink: 0;
}

.self-start {
  align-self: flex-start;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-with-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.row-with-wrap-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.row-align-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-align-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
