@import "../../css/colors";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/images/auth-background.png");
  background-repeat: no-repeat;
  background-position: center bottom 33px;
  background-size: contain;
  height: calc(100vh - 15px);
  padding-bottom: 33.5px;
  min-height: 550px;
}

.wrapper {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  text-align: center;
  margin-bottom: 32px;
}

.input-wrapper {
  position: relative;
  width: 485px;
  border-radius: 14px;
  background: $white;
  border: 0.5px solid rgba(0, 0, 0, 0.46);
  overflow: hidden;
  transition: 0.3s;
  &.error {
    border-color: $red;
  }
}

.arrow {
  position: absolute;
  top: 16.5px;
  right: 32px;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  width: 36px;
}

.arrow-active {
  cursor: pointer;
  opacity: 1;
}

.arrow-password {
  top: 86.5px;
  animation: show 0.3s ease-in;
}

.input {
  border: 0;
  outline: 0;
  width: 100%;
  color: $black;
  height: 69px;
  padding: 0 78px 0 32px;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
