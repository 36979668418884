@import "css/colors";
@import "css/texts";

.wrapper {
  border-radius: 14px;
  overflow: hidden;
  background-color: $black;
  width: 375px;
  height: 812px;
  position: sticky;
  top: 76px;
}

.header {
  z-index: 3;
  padding-top: 43px;
  left: 0;
  right: 0;
  top: 0;
  height: 87px;
  display: flex;
  align-items: flex-end;
  padding: 0 0 16px;
  position: absolute;
  backdrop-filter: blur(30px);
  background: hsla(0, 0%, 100%, 0.18);
}

.formsheet-wrapper {
  width: 100%;
  margin-top: 54px;
  scrollbar-color: rgba(255, 255, 255, 0.46) transparent;
  scrollbar-width: thin;
  overflow-y: overlay;
  height: 100%;
  padding-bottom: 152px;
  border-radius: 14px;
  position: relative;
  z-index: 6;
  border-radius: 14px;
  background-color: $black;
}

.content {
  width: 100%;
  position: relative;
  top: 32px;
  padding-top: 16px;
  overflow: hidden;
}

.background-image-wrapper {
  position: relative;
  z-index: 23;
  width: 375px;

  img {
    object-fit: cover;
  }
}

.location-logo-wrapper {
  position: absolute;
  left: 16px;
  right: 0;
  z-index: 1;
  bottom: -28px;
  gap: 16px;
  display: flex;
  flex-direction: row;

  img {
    border-radius: 14px;
    overflow: hidden;
  }
}

.discount-text {
  height: 64px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 14px;
}

.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
}

.button-with-icon {
  background: $greyMobile;
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 4px;
  width: 80px;
  height: 60px;
}

.section-wrapper {
  background-color: $greyMobile;
  border-radius: 14px;
  overflow: hidden;
  padding: 16px;
  width: 343px;
  margin-bottom: 32px;
}

.separator {
  width: 343px;
  height: 0.5px;
  background-color: $whiteGrey;
}

.arrow-icon {
  top: 2px;
  position: relative;
}

.images-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: relative;
  left: -16px;
  padding: 16px;
  overflow-x: scroll;
  margin-bottom: 16px;
  width: calc(100% + 32px);

  & div {
    position: relative;
    &::after {
      content: "From Owner";
      position: absolute;
      width: 70px;
      height: 20px;
      bottom: 4px;
      right: 0;
      z-index: 1;
      background-color: $grey;
      color: $white;
      border-top-left-radius: 14px;
      border-bottom-right-radius: 14px;
      font-size: 10px;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    &.ka::after {
      content: "მფოლების მიერ";
      position: absolute;
      width: 98px;
    }
  }
}

.image-cover {
  object-fit: cover;
  border-radius: 14px;

  &.auto {
    width: auto;
  }
}
