@import "colors";

.regular19-text {
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
}

.regular14-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.regular16-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.regular13-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.regular12-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.regular17-text {
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
}

.regular18-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.regular19-text {
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
}

.regular20-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
}

.regular24-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.regular32-text {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}

.regular44-text {
  font-weight: 400;
  font-size: 44px;
  line-height: 53px;
}

.medium12-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.medium14-text {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.medium16-text {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

.medium17-text {
  font-size: 17px;
  line-height: 21px;
  font-weight: 500;
}

.medium18-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.medium19-text {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
}

.medium20-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.medium24-text {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

.medium30-text {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
}

.medium32-text {
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;
}

.bold32-text {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}

.bold44-text {
  font-size: 44px;
  line-height: 53px;
  font-weight: 700;
}

.heavy54-text {
  font-weight: 900;
  font-size: 54px;
  line-height: 64px;
  color: $black;
}

.section-title {
  @extend .medium24-text;
  margin: 0 32px 16px;
}

.table-title {
  @extend .regular44-text;
  font-style: italic;
  white-space: nowrap;
  margin: 0 32px;
}

.break-word {
  word-break: break-word;
}
