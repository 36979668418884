@import "../../css/colors";

.map-wrapper {
  height: calc(100vh - 243.6px);
}

.marker {
  cursor: pointer !important;
}

.clusters-wrapper {
  h3 {
    margin-bottom: 16px;
  }
}
