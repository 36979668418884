.p6 {
  padding: 6px;
}

.p16 {
  padding: 16px;
}

.py16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.px16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px32 {
  padding-left: 32px;
  padding-right: 32px;
}

.px64 {
  padding-left: 64px;
  padding-right: 64px;
}

.pr0 {
  padding-right: 0px;
}

.pr16 {
  padding-right: 16px;
}

.pr64 {
  padding-right: 64px;
}

.pl64 {
  padding-left: 64px;
}
